<template>
  <div class="iss-grid-wrap mt-16">
    <grid
      ref="gridRef"
      :allow-selection="false"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :pagination="false"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 1640, y: gridHeight }"
    >
      <template #packageMoney="{ record }">
        <text>{{
          record.packageMoney == 0
            ? record.packageMoney
            : (record.packageMoney / 100).toFixed(2)
        }}</text>
      </template>
      <template #enable="{ record }">
        <a-switch
            v-model:checked="record.enable"
            checked-children="启用"
            un-checked-children="禁用"
            @click="handleClickSwitch(record)"
        />
      </template>

      <template #discountPrice="{ record }">
        <div>{{record.discountPrice / 100}}</div>
      </template>

      <template #isPromoActive="{ record }">
        <div v-if="record.isPromoActive">是</div>
        <div v-else>否</div>
      </template>

      <template #newUserOnly="{ record }">
        <div v-if="record.newUserOnly">是</div>
        <div v-else>否</div>
      </template>
      <template #promoPeriodActive="{ record }">
        <div v-if="record.promoPeriodActive">是</div>
        <div v-else>否</div>
      </template>


      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>
    <a-modal
      v-model:visible="visible"
      :title="!form.id ? '新增' : '编辑'"
      cancelText="取消"
      okText="确认"
      :width="600"
      centered
      @ok="handleOk"
    >
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        :model="form"
      >
        <a-form-item label="套餐ID" v-bind="validateInfos.packageCode">
          <a-input
              :disabled="form.id === '' ? false : true"
              v-model:value="form.packageCode"
              placeholder="请输入套餐ID"
          />
        </a-form-item>
        <a-form-item label="套餐名称" v-bind="validateInfos.packageTitle">
          <a-input
            v-model:value="form.packageTitle"
            placeholder="请输入套餐名称"
          />
        </a-form-item>
        <a-form-item label="套餐类型" v-bind="validateInfos.packageName">
          <a-input
            v-model:value="form.packageName"
            placeholder="请输入套餐类型"
          />
        </a-form-item>
        <!-- <a-form-item label="首次优惠占比" v-bind="validateInfos.discountRate">
          <a-input
            v-model:value="form.discountRate"
            placeholder="请输入首次优惠占比"
          />
        </a-form-item> -->
        <a-form-item
          label="套餐价格"
          v-bind="validateInfos.packageMoney"
          class="flex-row"
        >
          <a-input
            v-model:value="form.packageMoney"
            placeholder="请输入套餐价格"
          />
          <text class="row-uni">元</text>
        </a-form-item>
        <a-form-item label="启用状态">
          <a-switch
            v-model:checked="form.enable"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-item>
        <!-- 待开发 -->
        <!-- <a-form-item label="连续扣费">
          <a-switch
            v-model:checked="form.packageContract"
            checked-children="开"
            un-checked-children="关"
          />
        </a-form-item> -->

        <a-row>
          <a-col span="14">
            <a-form-item
              :label-col="{ span: 10 }"
              label="单次购买时长"
              v-bind="validateInfos.packageDuration"
              class="package-duration"
            >
              <a-input
                v-model:value="form.packageDuration"
                placeholder="请输入单次购买时长"
              />
              <div class="tip">时长需为大于等于1的整数</div>
            </a-form-item>
          </a-col>
          <a-col span="9" offset="1">
            <a-form-item v-bind="validateInfos.durationUnit">
              <a-select
                v-model:value="form.durationUnit"
                :options="unitOptions"
                placeholder="时间单位"
              />
            </a-form-item>
          </a-col>
        </a-row>
<!--         -->
        <a-form-item label="是否优惠" v-bind="validateInfos.isPromoActive">
          <a-switch
              v-model:checked="form.isPromoActive"
              checked-children="是"
              un-checked-children="否"
              @click="handleClickSwitchYouhui(record)"
          />
        </a-form-item>
        <a-form-item
            label="优惠后的价格"
            v-bind="validateInfos.discountPrice"
            class="flex-row"
            v-if="showTiaojian"
        >
<!--          <a-input-->
<!--              v-model:value="form.discountPrice"-->
<!--              placeholder="请输入优惠后的价格"-->
<!--          />-->
          <a-input-number
              v-model:value="form.discountPrice"
              placeholder="请输入优惠后价格"
              style="width: 100%"
              :min="0"
          />
          <text class="row-uni">元</text>
        </a-form-item>

        <a-form-item label="优惠条件" v-bind="validateInfos.newUserOnly" v-if="showTiaojian">
          <a-checkbox
              v-model:checked="form.newUserOnly"
              @change="handleCheck(form.newUserOnly)"
          >未订阅过的用户</a-checkbox>
        </a-form-item>

        <div class="shijianSel">
          <a-form-item label=" " v-bind="validateInfos.promoPeriodActive" v-if="showTiaojian"
          >
            <a-checkbox v-model:checked="form.promoPeriodActive">

            </a-checkbox>

            <a-range-picker
                :getPopupContainer="triggerNode => triggerNode.parentNode"
                v-model:value="form.TimePick"
                format="YYYY-MM-DD HH:mm:ss"
                :show-time="{ format: 'HH:mm:ss' }"
                @change="handleRangePicker(form.TimePick)"
                class="mr-16 ml-10"
                style="width: 92%; margin-left: 10px"
            >
              <template #suffixIcon>
                <CreditCardOutlined />
              </template>
            </a-range-picker>

<!--            <a-time-picker-->
<!--                class="iss-datepicker"-->
<!--                :getPopupContainer="triggerNode => triggerNode.parentNode"-->
<!--                v-model:value="form.discountStartTime"-->
<!--                value-format="HH:mm"-->
<!--                placeholder="请选择开始时间"-->
<!--                style="width: 40%"-->
<!--                @change="handleDiscountStartTime(form.discountStartTime)"-->
<!--            />-->
<!--            - -->
<!--            <a-time-picker-->
<!--                class="iss-datepicker"-->
<!--                v-model:value="form.discountEndTime"-->
<!--                :getPopupContainer="triggerNode => triggerNode.parentNode"-->
<!--                value-format="HH:mm"-->
<!--                placeholder="请选择结束时间"-->
<!--                style="width: 40%"-->
<!--                @change="handleDiscountEndTime(form.discountEndTime)"-->
<!--            />-->
          </a-form-item>
        </div>

      </a-form>

      <template v-slot:footer>
        <div class="footer">
          <div class="left">
<!--            <a-button type="primary" @click="handleFafang" :loading="loading">-->
<!--              为所有用户发放一次优惠资格-->
<!--            </a-button>-->
          </div>

          <div class="right">
            <a-button @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="handleOk" :loading="loading"
            >确认</a-button
            >

          </div>
        </div>

      </template>
    </a-modal>
  </div>
</template>
<script>
import {reactive, toRefs, ref, toRaw, createVNode, watch } from 'vue';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import {Row, Col, Form, Switch, Select, message, Modal, Checkbox, InputNumber,
  // TimePicker,
  DatePicker, } from 'ant-design-vue';
import configurationApi from '@/api/configuration.js';
import {
  CreditCardOutlined,
} from '@ant-design/icons-vue';
import moment from 'moment';

import * as icons from "@ant-design/icons-vue";
export default {
  components: {
    Operation,
    Grid,
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    CreditCardOutlined,
    ASwitch: Switch,
    ASelect: Select,
    ACheckbox: Checkbox,
    AInputNumber: InputNumber,
    // ATimePicker: TimePicker,
    ARangePicker: DatePicker.RangePicker,

  },
  setup() {
    const gridRef = ref();
    const state = reactive({
      loading: false,
      search: {
        type: 0,
      },
      TagList: [],
      visible: false,
      showTiaojian: false,
      unitOptions: [
        {
          value: 'DAY',
          label: '天',
        },
        {
          value: 'MONTH',
          label: '月',
        },
        {
          value: 'YEAR',
          label: '年',
        },
      ],
    });
    let form = reactive({
      id:'',
      packageTitle: '',
      packageCode: '',
      packageName: '',
      // discountRate: '',
      packageMoney: '',
      packageDuration: '',
      durationUnit: null,
      enable: true,
      packageContract: 0,
      newUserOnly: null,
      discountPrice: '',
      promoPeriodActive: false,
      discountStartTime: '',
      discountEndTime: '',
      isPromoActive: false,
      TimePick: [],


    });

    watch(
        ()=>form.isPromoActive,
        (value)=>{
          state.showTiaojian = value
          if(value) {
            console.log('value', value)
            form.isPromoActive = true
            // form.tiaojian = true
          }else {
            form.isPromoActive = false
            form.newUserOnly = false
            form.promoPeriodActive = false
            form.discountPrice = ''
            form.discountStartTime = ''
            form.discountEndTime = ''

          }
        }
    )

    const checkOne= (rule, value) => {
      let num = Number(value)
      if (value && !(!isNaN(num) && num >= 1 && Number.isInteger(num))) {
       return Promise.reject('输入值必须为大于0的整数');
      }else if(num>9999){
        return Promise.reject('输入值必须为小于10000的整数');
      }
     return Promise.resolve()
    };
    const checkZero = (rule, value) => {
      let num = Number(value)
      if (value && !(!isNaN(num) && num > 0)) {
        return Promise.reject('输入值必须为大于0');
      }else if(num>9999){
        return Promise.reject('输入值必须为小于10000的整数');
      }
      return Promise.resolve()
    };
    const required = { required: true, message: '不能为空' };
    const { resetFields, validate, validateInfos } = Form.useForm(form, {
      packageName: [required],
      // discountRate: [required],
      packageMoney: [required,
      {
          validator: checkZero,
          trigger: ['blur', 'change'],
        },],
      packageDuration: [
        required,
        {
          validator: checkOne,
          trigger: ['blur', 'change'],
        },
      ],
      durationUnit: [required],
      packageTitle: [required],
      packageCode: [required],
    });
    return {
      gridRef,
      ...toRefs(state),
      form,
      validateInfos,
      items: [
        { key: 'packageTitle', label: '套餐名称' },
        { key: 'packageName', label: '套餐类型' },
        { key: 'packageMoney', label: '套餐价格' },
        // { key: 'discount', label: '首次优惠' },
        { key: 'updateTime', label: '最后更新时间' },
        { key: 'updateUser', label: '操作人' },
        {
          key: 'enable',
          label: '启用状态',
          dataset: [
            { value: '0', label: '启用' },
            { value: '1', label: '禁用' },
          ],
        },
      ],
      columns: [
        {
          title: '序号',
          width: 80,
          ellipsis: true,
          customRender: ({ index }) => `${index + 1}`,
          align: 'center',
        },
        {
          dataIndex: 'packageTitle',
          title: '套餐名称',
          width: 160,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'packageCode',
          title: '套餐ID',
          width: 240,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'packageName',
          title: '套餐类型',
          width: 120,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'packageMoney',
          title: '套餐价格(元)',
          width: 180,
          ellipsis: true,
          slots: { customRender: 'packageMoney' },
          align: 'center',
        },
        {
          dataIndex: 'discountPrice',
          title: '优惠后价格',
          width: 120,
          ellipsis: true,
          slots: { customRender: 'discountPrice' },
        },
        {
          dataIndex: 'isPromoActive',
          title: '是否优惠',
          width: 100,
          ellipsis: true,
          slots: { customRender: 'isPromoActive' },
        },
        // {
        //   dataIndex: 'newUserOnly',
        //   title: '是否仅未订阅过的用户',
        //   width: 100,
        //   ellipsis: true,
        //   slots: { customRender: 'newUserOnly' },
        // },
        // {
        //   dataIndex: 'promoPeriodActive',
        //   title: '是否启用时间段优惠',
        //   width: 100,
        //   ellipsis: true,
        //   slots: { customRender: 'promoPeriodActive' },
        // },
        // {
        //   dataIndex: 'discountStartTime',
        //   title: '优惠时间段开始',
        //   width: 150,
        //   ellipsis: true,
        // },
        // {
        //   dataIndex: 'discountEndTime',
        //   title: '优惠时间段结束',
        //   width: 150,
        //   ellipsis: true,
        // },

        {
          dataIndex: 'updateTime',
          title: '最后更新时间',
          width: 180,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'updateUser',
          title: '操作人',
          width: 120,
          ellipsis: true,
          align: 'center',
        },
        {
          dataIndex: 'enable',
          width: 140,
          title: '启用状态',
          slots: { customRender: 'enable' },
          ellipsis: true,
          align: 'center',
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'operation' },
          align: 'center',
        },
      ],
      btnOperation: [
        {
          type: 'add',
          label: '',
          icon: 'PlusOutlined',
          permission: 'false',
          fnClick: () => {
            state.visible = true;
            resetFields();
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '修改',
          icon: 'EditTwoTone',
          permission: 'false',
          fnClick: record => {
            resetFields();
            state.visible = true;
            Object.assign(form, record);
            form.TimePick.push(record.discountStartTime)
            form.TimePick.push(record.discountEndTime)
            // console.log('from', form)
            if (form.packageMoney != 0) {
              form.packageMoney = (record.packageMoney / 100).toFixed(2);
            }
            if (form.discountPrice) {
              form.discountPrice = (record.discountPrice / 100).toFixed(2);
            }
          },
        },
        {
          type: 'delete',
          label: '删除',
          icon: 'DeleteTwoTone',
          permission:'',
          fnClick: ({ id }) => {
            configurationApi
              .delete(`configuration:delete`, { ids: [id] })
              .then(() => {
                state.visible = false;
                message.success('操作成功');
                gridRef.value.refreshGrid();
              });
          },
        },
      ],
      url: configurationApi.pageUrl,
      gridHeight: document.body.clientHeight - 386,
      handleOk() {
        validate().then(() => {

          // icon: createVNode(icons['ExclamationCircleOutlined']),
          Modal.confirm({
            title: `套餐ID需要到苹果后台同步配置`,
            // okType: 'danger',
            onOk: () => {
              state.loading = true;
              if (form.isPromoActive) {
                // if (!form.newUserOnly) {
                //   return message.error('请勾选优惠条件')
                // }
                 console.log('from.promoPeriodActive保存', form.promoPeriodActive, form.discountStartTime, form.discountEndTime )
                if (!form.newUserOnly && !form.promoPeriodActive) {
                  state.loading = false;
                  return message.error('请勾选优惠条件或者勾选时间')
                }
                if (!form.discountPrice) {
                  state.loading = false;
                  return message.error('优惠后的价格不能为空')
                }
                if (form.promoPeriodActive && (!form.discountStartTime || !form.discountEndTime)) {
                  debugger
                    state.loading = false;
                    return message.error('请选择时间')
                }


              }

              let type = 'add';
              form?.id && (type = 'update');
              let data = { ...toRaw(form) };
              data.packageContract = 0;
              data.packageDesc = data.packageTitle;
              data.packageMoney = data.packageMoney * 100;
              if (data.discountPrice === 19.9 || data.discountPrice === 19.90) {
                data.discountPrice = 1990
              } else {
                data.discountPrice = data.discountPrice * 100;
              }

              // console.log('data', data)
              configurationApi[type](`configuration:${type}`, {
                packageType: state.search.type,
                ...data,
              }).then(() => {
                state.loading = false;
                state.visible = false;
                message.success('操作成功');
                gridRef.value.refreshGrid();
              });

            },

          });

        });
      },
      handleCancel() {
        state.visible = false;

      },
      // 为所有用户发放一次优惠资格
      handleFafang() {

      },
      // handleDiscountStartTime(value) {
      //   console.log('value', value)
      //   form.discountStartTime = value
      // },
      handleRangePicker() {
        // console.log('handleRangePicker', form.TimePick)
        if (form.TimePick.length > 0) {
          form.discountStartTime = form.TimePick[0]
              .format('YYYY-MM-DD HH:mm:ss')
              .toString();
          form.discountEndTime = form.TimePick[1]
              .format('YYYY-MM-DD HH:mm:ss')
              .toString();
        } else {
          form.discountStartTime = ''
          form.discountEndTime = ''
        }

        // console.log('日期', form.discountStartTime, form.discountEndTime)

      },

      // handleDiscountEndTime(value) {
      //   console.log('value', value)
      //   form.discountEndTime = value
      // },

      handleCheck(value) {
        form.newUserOnly = value
      },
      handleClickSwitchYouhui(record) {

        configurationApi.updateStatus('', {
          id: record.id,
          enable: record.enable
        }).then((res) => {
         if (res) {
           message.success('操作成功');
           gridRef.value.refreshGrid();
         }
        });

      },


    };
  },
};
</script>
<style lang="less" scoped>
.flex-row {
  /deep/.ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
}
.package-duration {
  /deep/ .ant-form-item-control-input-content {
    position: relative;
    .tip {
      position: absolute;
      color: #ff7b00;
      font-size: 12px;
      bottom: -22px;
      left: 0;
    }
  }
}

.flex-row {
  position: relative;
  .row-uni {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
  }
}
/deep/ .ant-table-body{
    max-height: 70vh !important;
  }

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {

  }
  .right {

  }

}
.shijianSel /deep/ .ant-form-item-label > label {
  display: none;
}

</style>